import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';

// campus gallery
// import Campus from '../../components/campus.js'

import Marina from '../../pics/team/marina.jpg'
import GK from '../../pics/team/gk.jpg'
import Chris from '../../pics/team/chris.jpg'
import Adria from '../../pics/team/adria.jpg'
import Pawel from '../../pics/team/pawel.jpeg'
import Max from '../../pics/team/max.jpeg'
// import Vladimir from '../../pics/team/vladimir_manaev.jpeg'
import Minerva from "../../pics/team/minerva_singh.jpeg"

const School = () => (
	<div className='flexContent'>
		{/*<div className="denseGrid">*/}
		<Helmet

			title="Barcelona Code School"
			meta={[
				{ name: 'description', content: 'About Barcelona Code School' },
				{ name: 'keywords', content: 'barcelona code school, barcelona coding school, bcncoding' },
				{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
			]}
		/>
		<div className='transparentContainer spanAcrossGrid flex1200'>
			<h1>What is Barcelona Code School</h1>
		</div>

		<div className='transparentContainer'>
			<article className='whiteCard spanAcrossGrid'>
				<p>Barcelona Code School is one of the top-ranked code schools offering immersive <a href='/barcelona-code-school/javascript-full-stack-bootcamp/'>full-stack training</a> on web and mobile development with JavaScript MERN stack (Node.js, Express, MongoDB, React.js) and React Native.</p>
				<p>This bootcamp is aimed at preparing junior software engineers. Combined with the job support we offer the bootcamp is a powerful springboard into starting a new career which helped hundreds of our graduates to get into tech and change their lives for the better. </p>

				<p>If your plan is to break into the Game Development industry we offer the <a href='/game-development-bootcamp/'>Game Development Bootcamp</a> with Unity and C# and up to 11 games included into the curriculum plus a free personal project. By the end of it you will be prepared to start working as a junior game developer or go indie and create your own prototypes.</p>

				<p>Another track we offer is <a href='/machine-learning-and-ai-engineering-bootcamp/'>Machine Learning and AI development engineering bootcamp</a>. Designed for the students willing to enter the Machine Learning field with skills such as Deep Learning, Neural Networks and AI development.</p>

				<p>For those looking for a career in tech which doesn't involve coding we offer the <a href='/ux-design-bootcamp-in-barcelona-code-school/'>UX/UI Design Bootcamp</a> to enter the fascinating field of User Experience Design.</p>

				<p>Thanks to our top-notch curriculum and one of the best student/mentors ratios we achieve truly in-depth learning experience and succeed in preparing students for a job-ready level in 9 weeks of full-time training.</p>

				{/*online training*/}

				{/*all the scholarships*/}
				<p>Since the very start in 2015 we carry on the “Women in Tech” scholarship and supporting underrepresented groups through numerous collaborations and partnerships.</p>
			</article>
		</div>


		{/*<h1>This is our campus</h1>
<Campus />*/}


		<div className='transparentContainer spanAcrossGrid flexFullWidth'>
			<h1>Who are we?</h1>
		</div>








		<article className='transparentContainer flex300'>
			<h2 >George Kovalev</h2>
			<h2 >Developer</h2>
			<div className='whiteCard'>
				<img className='offsetImage' style={{
					width: '40%',
					float: 'left',
					margin: '0 1em 1em 0'
				}}
					src={GK} />
				<p>Coding has been a life-long passion for George which started from creating his first game when he was ten years old. In BCS he is responsible for creating, updating and maintaining the curriculum for the Web / Mobile Development Bootcamp.</p>

				<p>His experience in both entrepreneurship and coding gives a unique perspective on the applied coding making sure it is relevant for the real life after the bootcamp. </p>
				<p>Team: <a href='/barcelona-code-school/javascript-full-stack-bootcamp/'>Web / Mobile Development Bootcamp</a></p>
				<div className='cleaner' />
			</div>
		</article>

		<article className='transparentContainer flex300'>
			<h2 >Chris Kelly</h2>
			<h2 >UX Designer</h2>
			<div className='whiteCard'>
				<img className='offsetImage' style={{
					width: '40%',
					float: 'left',
					margin: '0 1em 1em 0'
				}}
					src={Chris} />
				<p>Graduate of Central St. Martins School of Art and Design, Chris’s transition into UX came through his background in media working on Film and TV series for 20th Century Fox, Ripple World Pictures, BBC, Entertainment One and Netflix.</p>
				<p>He has been working as a UX and UI designer for years as a freelancer delivering projects across different industries as well as teaching UX Design at BCS. His ability to understand client's needs and come up with the best outcomes is leading students through their projects and making sure that everybody learns UX in the most practical hands-on approach aligned with user centric workflow. Through a variety of influences, Chris combines his understanding of visual composition and narrative to inform his work believing that the individual and the subconscious psychological drivers that influence us, should be at the heart of every ethical design decision make. </p>
				<p>Team: <a href='/ux-design-bootcamp-in-barcelona-code-school/'>UX/UI Design Bootcamp</a></p>
				<div className='cleaner' />
			</div>
		</article>



		{/*<article className='whiteCard'>
<img className='offsetImage' style={{    
width: '40%',
float: 'left',
margin: '0 1em 1em 0'}}
src={Sophie}/>
<h2 >Sophie Goldenberg</h2>
<h2 >Graphic designer</h2>

<p>Graphic & Motion Designer, she has always been passionately interested in art, design and filmmaking. Sophie studied Fine Arts in Moscow, but then moved to Barcelona to study Graphic Design. Sine then she has been working on personal projects, improving her skills in every artistic area possible.</p>
</article>*/}




		<article className='transparentContainer flex300'>
			<h2 >Paweł Kornas</h2>
			<h2 >Developer</h2>
			<div className='whiteCard'>
				<img className='offsetImage' style={{
					width: '40%',
					float: 'left',
					margin: '0 1em 1em 0'
				}}
					src={Pawel} />

				<p>After working as a maritime engineer Paweł was looking for new challenges in life and decided to dive into the world of programming. He moved to Barcelona in 2021 to participate in the Web / Mobile Development Bootcamp at the school. After successful completion with outstanding results, he was offered the position as a full-time instructor.</p>
				<p>Pawel draws upon his background in engineering to meet students' needs and convey his knowledge by employing a variety of different approaches.</p>
				<p>"Going through the BCS programme gave me a lot of joy and my goal is to pass on my knowledge and ignite a love for programming in new programmers starting off in the industry".</p>
				<p>Team: <a href='/barcelona-code-school/javascript-full-stack-bootcamp/'>Web / Mobile Development Bootcamp</a></p>
				<div className='cleaner' />
			</div>
		</article>





		<article className='transparentContainer flex300'>
			<h2 >Dr. Minerva Singh</h2>
			<h2 >ML & AI Developer</h2>
			<div className='whiteCard'>
				<img className='offsetImage' style={{
					width: '40%',
					float: 'left',
					margin: '0 1em 1em 0'
				}}
					src={Minerva} />

				<p>Minerva holds a PhD from the University of Cambridge, MPhil from the School of Geography and Environment and an MSc from the Department of Engineering at Oxford University. With over a decade of experience in academic research, Minerva has contributed to renowned peer-reviewed journals like PLOS One, showcasing expertise in data science, deep learning, and earth observation (EO) for both non-governmental and industry stakeholders.</p>


				<p>Team: <a href='/machine-learning-and-ai-engineering-bootcamp/'>Deep Learning and Artificial Intelligence Engineering Bootcamp</a></p>
				<div className='cleaner' />
			</div>
		</article>


		<article className='transparentContainer flex300' style={{ gridColumn: 'span 2' }}>
			<h2 >Max Karacun</h2>
			<h2 >UX/UI Designer</h2>
			<div className='whiteCard'>
				<img className='offsetImage' style={{
					width: '40%',
					float: 'left',
					margin: '0 1em 1em 0'
				}}
					src={Max} />

				<p>Max is an UX/UI designer whose work experience includes being a Product Designers in companies like Glovo, Revolut, Founders Factory. He's bringing his expertise along with the invaluable insights into how big and small companies work with UX which is really helpful for the students to understand what to expect and how to prepare best for their new career.</p>
				<p>On top of that through the years Max has been working as a mentor both in-company and independently teaching User Experience and User Interface design.</p>

				<p>Team: <a href='/ux-design-bootcamp-in-barcelona-code-school/'>UX/UI Design Bootcamp</a></p>
				<div className='cleaner' />
			</div>
		</article>

		<article className='transparentContainer flex300'>
			<h2 >Marina Tudela</h2>
			<h2 >UX Designer</h2>
			<div className='whiteCard'>
				<img className='offsetImage' style={{
					width: '40%',
					float: 'left',
					margin: '0 1em 1em 0'
				}}
					src={Marina} />

				<p>My work seeks the harmony between service and beauty. With a background in visual communication, I am currently immersed in Experience Design at Haufe, a company focusing in innovative media and software solutions.</p>
				<p>Marina is the mastermind behind our original UX/UI Design Bootcamp curriculum.</p>
				<p>Team: <a href='/ux-design-bootcamp-in-barcelona-code-school/'>UX/UI Design Bootcamp</a></p>
				<div className='cleaner' />
			</div>
		</article>



		<article className='transparentContainer flex300' >
			<h2 >Adrià Compte Rossell</h2>
			<h2 >Product Designer / UX Engineer</h2>
			<div className='whiteCard'>
				<img className='offsetImage' style={{
					width: '40%',
					float: 'left',
					margin: '0 1em 1em 0'
				}}
					src={Adria} />

				<p>Audiovisual Systems Engineer with 10 years of experience in the digital product design space. Has worked for companies like HP and Softonic as a UX Engineer, and is currently working as a Senior Product Designer at Nespresso.</p>
				<p>Expert in Lean methodologies and the whole product design cycle, has participated on the creation of multiple design systems, built different entrepreneurship projects, is passionate about data visualization, and is currently exploring the creative coding scene.</p>
				<p>Team: <a href='/ux-design-bootcamp-in-barcelona-code-school/'>UX/UI Design Bootcamp</a></p>
				<div className='cleaner' />
			</div>
		</article>



		{/*<article className='transparentContainer flex300'>
<h2>Matej Murín</h2>
<h2>Developer</h2>
<div className='whiteCard'>
<img className='offsetImage' style={{    
width: '40%',
float: 'left',
margin: '0 1em 1em 0'}}
src={Matej}/>

<p>Matej is a passionate and skilled software developer prolific in React.js, React Native, NodeJS, Mongo, Python, Docker.</p> 
<p>His teaching experience in learn2code.sk and developer's experience in companies like thinkcreatix, yeself, profit365 give Matej a perfect combination of real-life devs skills and understanding of teaching process.</p>
<p>Team: Kids courses</p>
<div className='cleaner'/>
</div>
</article>*/}
	</div>
)

export default School
